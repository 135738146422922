import { createHttpEndpoint } from '../../utils'

import {
  type RetrieveClientBillingAddressData,
  type UpdateClientBillingAddressPayload,
} from './types'

/**
 * Retrieve Client billing address
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-client-api/definition#tag/billing-address/operation/retrieveClientBillingAddress}
 */
export const getBillingAddress =
  createHttpEndpoint<RetrieveClientBillingAddressData>({
    method: 'GET',
    operationId: 'retrieveClientBillingAddress',
    path: '/bm/client/addresses/billing',
  })

/**
 * Update Client billing address
 * DevPortal not updated for this API, see here: https://imperatorz.slack.com/archives/C01BHDKAA4T/p1736932704098299
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-client-api/definition}
 */
export const postBillingAddress =
  createHttpEndpoint<UpdateClientBillingAddressPayload>({
    method: 'POST',
    operationId: 'postClientAddress',
    path: '/bm/client/addresses/v2/billing',
  })
